import { db, auth, functions, storage } from "@/services/fb";
import store from ".";

export default {
  storeInit({ dispatch }) {
    dispatch("getSideNavigationImages");
    dispatch("getCarouselImages");
    dispatch("getPropertyDetails");
    dispatch("getLightboxImages");
    dispatch("getFloorPlanImages");
    dispatch("getAmenitiesImages");
    dispatch("getAmenitiesList");
    dispatch("getUtilitiesImages");
    dispatch("getUtilitiesList");
    dispatch("getNeighborhoodList");
    dispatch("getVirtualTourList");
    dispatch("getTVImages");
    dispatch("getTVLog");
  },
  updateControls({ commit }) {
    commit("UPDATE_TOOLBAR_NAVIGATION");
    commit("UPDATE_SIDE_NAVIGATION");
    commit("UPDATE_WINDOW");
  },
  getPropertyDetails({ commit }) {
    db.collection("property-details")
      .get()
      .then(snapshot => {
        if (snapshot && snapshot.docs) {
          commit("GET_PROPERTY_DETAILS_CONTENT", snapshot.docs);
        } else {
          console.log("No property-details found");
        }
      })
      .catch(err => {
        console.log("Error: Getting property-details: " + err.message);
      });
  },
  getSideNavigationImages({ commit }) {
    db.collection("side-navigation-images")
      .orderBy("order")
      .get()
      .then(snapshot => {
        if (snapshot && snapshot.docs) {
          commit("GET_SIDE_NAVIGATION_IMAGES", snapshot.docs);
        } else {
          console.log("No side-navigation-images found");
        }
      })
      .catch(err => {
        console.log("Error: Getting side-navigation-images: " + err.message);
      });
  },
  getCarouselImages({ commit }) {
    db.collection("carousel-images")
      .orderBy("order")
      .get()
      .then(snapshot => {
        if (snapshot && snapshot.docs) {
          commit("GET_CAROUSEL_IMAGES", snapshot.docs);
        } else {
          console.log("No carousel-images found");
        }
      })
      .catch(err => {
        console.log("Error: Getting carousel-images: " + err.message);
      });
  },
  getLightboxImages({ commit }) {
    db.collection("lightbox-images")
      .orderBy("order")
      .get()
      .then(snapshot => {
        if (snapshot && snapshot.docs) {
          commit("GET_LIGHTBOX_IMAGES", snapshot.docs);
        } else {
          console.log("No lightbox-images found");
        }
      })
      .catch(err => {
        console.log("Error: Getting lightbox-images: " + err.message);
      });
  },
  getFloorPlanImages({ commit }) {
    db.collection("floor-plan-images")
      .orderBy("order")
      .get()
      .then(snapshot => {
        if (snapshot && snapshot.docs) {
          commit("GET_FLOOR_PLANS_IMAGES", snapshot.docs);
        } else {
          console.log("No floor-plan-images found");
        }
      })
      .catch(err => {
        console.log("Error: Getting floor-plan-images: " + err.message);
      });
  },
  getAmenitiesImages({ commit }) {
    db.collection("amenities-images")
      .orderBy("order")
      .get()
      .then(snapshot => {
        if (snapshot && snapshot.docs) {
          commit("GET_AMENITIES_IMAGES", snapshot.docs);
        } else {
          console.log("No amenities-images found");
        }
      })
      .catch(err => {
        console.log("Error: Getting amenities-images: " + err.message);
      });
  },
  getAmenitiesList({ commit }) {
    db.collection("amenities-list")
      .get()
      .then(snapshot => {
        if (snapshot && snapshot.docs && snapshot.docs.length > 0) {
          commit("GET_AMENITIES_LIST", snapshot.docs);
        } else {
          console.log("No amenities-list found");
        }
      })
      .catch(err => {
        console.log("Error: Getting amenities-list: " + err.message);
      });
  },
  getUtilitiesImages({ commit }) {
    db.collection("utilities-images")
      .orderBy("order")
      .get()
      .then(snapshot => {
        if (snapshot && snapshot.docs && snapshot.docs.length > 0) {
          commit("GET_UTILITIES_IMAGES", snapshot.docs);
        } else {
          console.log("No utilities-images found");
        }
      })
      .catch(err => {
        console.log("Error: Getting utilities-images: " + err.message);
      });
  },
  getUtilitiesList({ commit }) {
    db.collection("utilities-list")
      .get()
      .then(snapshot => {
        if (snapshot && snapshot.docs && snapshot.docs.length > 0) {
          commit("GET_UTILITIES_LIST", snapshot.docs);
        } else {
          console.log("No utilities-list found");
        }
      })
      .catch(err => {
        console.log("Error: Getting utilities-list: " + err.message);
      });
  },
  getVirtualTour({ commit }) {
    db.collection("virtual-tour")
      .orderBy("order")
      .get()
      .then(snapshot => {
        if (snapshot && snapshot.docs && snapshot.docs.length > 0) {
          commit("GET_VIRTUAL_TOUR", snapshot.docs);
        } else {
          console.log("No virtual-tour found");
        }
      })
      .catch(err => {
        console.log("Error: Getting virtual-tour: " + err.message);
      });
  },
  getNeighborhoodList({ commit }) {
    db.collection("neighborhood-list")
      .get()
      .then(snapshot => {
        if (snapshot && snapshot.docs && snapshot.docs.length > 0) {
          commit("GET_NEIGHBORHOOD_LIST", snapshot.docs);
        } else {
          console.log("No neighborhood-list found");
        }
      })
      .catch(err => {
        console.log("Error: Getting neighborhood-list: " + err.message);
      });
  },
  getVirtualTourList({ commit }) {
    db.collection("virtual-tour-list")
      .get()
      .then(snapshot => {
        if (snapshot && snapshot.docs && snapshot.docs.length > 0) {
          commit("GET_VIRTUAL_TOUR_LIST", snapshot.docs);
        } else {
          console.log("No virtual-tour-list found");
        }
      })
      .catch(err => {
        console.log("Error: Getting virtual-tour-list: " + err.message);
      });
  },
  openContactUsDialog({ commit }, open) {
    commit("OPEN_CONTACT_US_DIALOG", open);
  },
  submitContactUs({ commit, getters }, form) {
    commit("CONTACT_US_LOADING", true);

    form.property = {
      name: getters.property.name ? getters.property.name : "",
      phone: getters.property.phone ? getters.property.phone : "",
      email: getters.property.email ? getters.property.email : "",
      web: getters.property.web ? getters.property.web : ""
    };

    db.collection("contact-us")
      .add(form)
      .then(() => {
        commit("OPEN_CONTACT_US_DIALOG", false);
        commit("CONTACT_US_LOADING", false);
        commit("SHOW_SNACKBAR", {
          show: true,
          result: true,
          message: "Message sent"
        });
      })
      .catch(err => {
        commit("CONTACT_US_LOADING", false);
        commit("SHOW_SNACKBAR", {
          show: true,
          result: false,
          message: "Error sending a message"
        });

        console.log("Error: Adding contact-us: " + err.message);
      });
  },
  submitApplyHere({ commit, getters }, form) {
    commit("APPLY_HERE_LOADING", true);

    form.property = {
      name: getters.property.name ? getters.property.name : "",
      phone: getters.property.phone ? getters.property.phone : "",
      email: getters.property.email ? getters.property.email : "",
      web: getters.property.web ? getters.property.web : ""
    };

    db.collection("apply-here")
      .add(form)
      .then(() => {
        commit("APPLY_HERE_LOADING", false);
        commit("SHOW_SNACKBAR", {
          show: true,
          result: true,
          message: "Request sent"
        });
      })
      .catch(err => {
        commit("APPLY_HERE_LOADING", false);
        commit("SHOW_SNACKBAR", {
          show: true,
          result: false,
          message: "Error sending a request"
        });

        console.log("Error: Adding apply-here: " + err.message);
      });
  },
  getTVImages({ commit }) {
    db.collection("tv-images")
      .orderBy("order")
      .get()
      .then(snapshot => {
        if (snapshot && snapshot.docs && snapshot.docs.length > 0) {
          commit("GET_TV_IMAGES", snapshot.docs);
        } else {
          console.log("No tv-images found");
        }
      })
      .catch(err => {
        console.log("Error: Getting tv-images: " + err.message);
      });
  },
  getTVLog({ commit }) {
    db.collection("tv-log")
      .orderBy("logDate", "desc")
      .get()
      .then(snapshot => {
        if (snapshot && snapshot.docs) {
          commit("GET_TV_LOG", snapshot.docs);
        } else {
          console.log("No tv-log found");
        }
      })
      .catch(err => {
        console.log("Error: Getting tv-log: " + err.message);
      });
  },
  // eslint-disable-next-line no-unused-vars
  deleteAllTVLog({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      return functions
        .httpsCallable("recursiveDelete")({ path: "tv-log" })
        .then(() => {
          resolve("Logs deleted");
        })
        .catch(err => {
          console.error("Error deleting tv-logs: " + err.message);
          reject("Error deleting logs. " + err.message);
        });
    });
  },
  showSnackbar({ commit }, payload) {
    const show = payload.show;
    const result = payload.result;
    const message = payload.message;

    commit("SHOW_SNACKBAR", {
      show: show,
      result: result,
      message: message
    });
  },
  closeSnackbar({ commit }) {
    commit("SHOW_SNACKBAR", {
      show: false
    });
  },
  changeAuthReady({ commit }) {
    commit("CHANGE_AUTH_READY");
  },
  changeLoginDialogType({ commit }, type) {
    commit("CHANGE_LOGIN_DIALOG_TYPE", type);
  },
  openRegisterDialog({ commit, state }, open) {
    store.dispatch("changeLoginDialogType", false);

    if (!state.user) {
      commit("OPEN_LOGIN_DIALOG", open);
    }
  },
  openLoginDialog({ commit, state }, open) {
    store.dispatch("changeLoginDialogType", true);

    if (!state.user) {
      commit("OPEN_LOGIN_DIALOG", open);
    }
  },
  register({ commit }, form) {
    commit("LOGIN_LOADING", true);

    return new Promise((resolve, reject) => {
      auth
        .createUserWithEmailAndPassword(form.email, form.password)
        .then(credential => {
          return db
            .collection("users")
            .doc(credential.user.uid)
            .set({
              type: "admin"
            });
        })
        .then(() => {
          commit("OPEN_LOGIN_DIALOG", false);
          commit("LOGIN_LOADING", false);
          commit("SHOW_SNACKBAR", {
            show: true,
            result: true,
            message: "User created"
          });

          resolve("User created");
        })
        .catch(err => {
          commit("LOGIN_LOADING", false);
          commit("SHOW_SNACKBAR", {
            show: true,
            result: false,
            message: "Error creating a user. " + err.message
          });

          console.log("Error: Creating user: " + err.message);
          reject("Error creating a user. " + err.message);
        });
    });
  },
  login({ commit }, form) {
    commit("LOGIN_LOADING", true);

    return new Promise((resolve, reject) => {
      auth
        .signInWithEmailAndPassword(form.email, form.password)
        .then(() => {
          commit("OPEN_LOGIN_DIALOG", false);
          commit("LOGIN_LOADING", false);
          commit("SHOW_SNACKBAR", {
            show: true,
            result: true,
            message: "Login successful"
          });

          resolve("Login successful");
        })
        .catch(err => {
          commit("LOGIN_LOADING", false);
          commit("SHOW_SNACKBAR", {
            show: true,
            result: false,
            message: "Error login. " + err.message
          });

          console.log("Error: Login user: " + err.message);
          reject("Error login. " + err.message);
        });
    });
  },
  logoff({ commit }) {
    return new Promise((resolve, reject) => {
      auth
        .signOut()
        .then(() => {
          commit("SHOW_SNACKBAR", {
            show: true,
            result: true,
            message: "Logoff successful"
          });

          resolve("Logoff successful");
        })
        .catch(err => {
          commit("SHOW_SNACKBAR", {
            show: true,
            result: false,
            message: "Error logoff. " + err.message
          });

          console.log("Error: Logoff user: " + err.message);
          reject("Error logoff. " + err.message);
        });
    });
  },
  updateUser({ commit }, user) {
    commit("UPDATE_USER", user);
  },
  updateControlsAdmin({ commit }) {
    commit("UPDATE_TOOLBAR_NAVIGATION_ADMIN");
  },
  // eslint-disable-next-line no-unused-vars
  getImageUrl({ commit }, image) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        resolve({ message: "Image Url found.", url: reader.result });
      };

      reader.onerror = () => {
        reject("Error getting image data. ");
      };

      if (image) {
        reader.readAsDataURL(image);
      }
    });
  },
  // eslint-disable-next-line no-unused-vars
  getImageData({ commit }, url) {
    return new Promise((resolve, reject) => {
      var request = new XMLHttpRequest();
      request.responseType = "blob";

      request.onload = () => {
        const image = request.response;
        resolve({ message: "Image data received.", image: image });
      };

      request.onerror = () => {
        console.log("Error getting image data. ");
        reject("Error getting image data. ");
      };

      request.open("GET", url);
      request.send();
    });
  },
  updatePropertyDetailsContent({ commit }, propertyDetails) {
    return new Promise((resolve, reject) => {
      db.collection("property-details")
        .doc("par")
        .update({
          text: propertyDetails
        })
        .then(() => {
          commit("UPDATE_PROPERTY_DETAILS_CONTENT", propertyDetails);

          commit("SHOW_SNACKBAR", {
            show: true,
            result: true,
            message: "Item updated"
          });

          resolve("Item updated");
        })
        .catch(err => {
          commit("SHOW_SNACKBAR", {
            show: true,
            result: false,
            message: "Error updating item"
          });

          console.log("Error: Updating Item: " + err.message);
          reject("Error updating item. " + err.message);
        });
    });
  },
  createImage({ commit }, payload) {
    const collection = payload.collection;
    const lines = payload.lines;

    const item = {
      fileName: payload.item.fileName,
      url: payload.item.url,
      order: payload.item.order
    };

    if (lines >= 1) {
      item.line1 = payload.item.line1;
    }

    if (lines >= 2) {
      item.line2 = payload.item.line2;
    }

    if (lines >= 3) {
      item.line3 = payload.item.line3;
    }

    const image = payload.item.image;

    let uploadFileName;
    let imageRef;

    return new Promise((resolve, reject) => {
      db.collection(collection)
        .add(item)
        .then(doc => {
          item.id = doc.id;
          return item.id;
        })
        .then(id => {
          const fileName = image.name;
          const ext = fileName.slice(fileName.lastIndexOf("."));
          uploadFileName = collection + "/" + id + ext;

          imageRef = storage.ref().child(uploadFileName);
          return imageRef;
        })
        .then(imageRef => {
          return imageRef.put(image);
        })
        .then(() => {
          return imageRef.getDownloadURL();
        })
        .then(url => {
          item.fileName = uploadFileName;
          item.url = url;

          return db
            .collection(collection)
            .doc(item.id)
            .update({
              fileName: uploadFileName,
              url: url
            });
        })
        .then(() => {
          commit("SHOW_SNACKBAR", {
            show: true,
            result: true,
            message: "Item created"
          });

          resolve({ message: "Item created", item: item });
        })
        .catch(err => {
          commit("SHOW_SNACKBAR", {
            show: true,
            result: false,
            message: "Error creating item"
          });

          console.log("Error: Adding Item: " + err.message);
          reject("Error adding item. " + err.message);
        });
    });
  },
  updateImage({ commit, dispatch }, payload) {
    const collection = payload.collection;
    const lines = payload.lines;
    const formImageChanged = payload.formImageChanged;

    const item = {
      id: payload.item.id,
      line1: payload.item.line1,
      fileName: payload.item.fileName,
      url: payload.item.url,
      order: payload.item.order
    };

    if (lines >= 1) {
      item.line1 = payload.item.line1;
    }

    if (lines >= 2) {
      item.line2 = payload.item.line2;
    }

    if (lines >= 3) {
      item.line3 = payload.item.line3;
    }

    const image = payload.item.image;

    let imageRef;

    return new Promise((resolve, reject) => {
      dispatch("updateImageDoc", {
        collection: collection,
        lines: lines,
        item: item
      })
        .then(() => {
          if (!formImageChanged) {
            return;
          } else {
            return storage
              .ref()
              .child(item.fileName)
              .delete()
              .then(() => {
                imageRef = storage.ref().child(item.fileName);
                return imageRef;
              })
              .then(imageRef => {
                return imageRef.put(image);
              })
              .then(() => {
                return imageRef.getDownloadURL();
              })
              .then(url => {
                item.url = url;

                return db
                  .collection(collection)
                  .doc(item.id)
                  .update({
                    url: url
                  });
              });
          }
        })
        .then(() => {
          commit("SHOW_SNACKBAR", {
            show: true,
            result: true,
            message: "Item updated"
          });

          resolve({ message: "Item updated", item: item });
        })
        .catch(err => {
          commit("SHOW_SNACKBAR", {
            show: true,
            result: false,
            message: "Error updating item"
          });

          console.log("Error: Updating Item: " + err.message);
          reject("Error updating item. " + err.message);
        });
    });
  },
  // eslint-disable-next-line no-unused-vars
  updateImageDoc({ commit }, payload) {
    const collection = payload.collection;
    const lines = payload.lines;
    const item = payload.item;

    if (lines == 0) {
      return db
        .collection(collection)
        .doc(item.id)
        .update({
          fileName: item.fileName,
          url: item.url,
          order: item.order
        });
    } else if (lines == 1) {
      return db
        .collection(collection)
        .doc(item.id)
        .update({
          line1: item.line1,
          fileName: item.fileName,
          url: item.url,
          order: item.order
        });
    } else if (lines == 2) {
      return db
        .collection(collection)
        .doc(item.id)
        .update({
          line1: item.line1,
          line2: item.line2,
          fileName: item.fileName,
          url: item.url,
          order: item.order
        });
    } else {
      return db
        .collection(collection)
        .doc(item.id)
        .update({
          line1: item.line1,
          line2: item.line2,
          line3: item.line3,
          fileName: item.fileName,
          url: item.url,
          order: item.order
        });
    }
  },
  createImageWithText({ commit }, payload) {
    const collection = payload.collection;
    const lines = payload.lines;

    const item = {
      fileName: payload.item.fileName,
      url: payload.item.url,
      order: payload.item.order
    };

    if (payload.item.text) {
      item.text = payload.item.text
    }
    else {
      item.text = ''
    }

    if (payload.item.underlay) {
      item.underlay = payload.item.underlay
    }
    else {
      item.underlay = ''
    }

    if (lines >= 1) {
      item.line1 = payload.item.line1;
    }

    if (lines >= 2) {
      item.line2 = payload.item.line2;
    }

    if (lines >= 3) {
      item.line3 = payload.item.line3;
    }

    const image = payload.item.image;

    let uploadFileName;
    let imageRef;

    return new Promise((resolve, reject) => {
      db.collection(collection)
        .add(item)
        .then(doc => {
          item.id = doc.id;
          return item.id;
        })
        .then(id => {
          const fileName = image.name;
          const ext = fileName.slice(fileName.lastIndexOf("."));
          uploadFileName = collection + "/" + id + ext;

          imageRef = storage.ref().child(uploadFileName);
          return imageRef;
        })
        .then(imageRef => {
          return imageRef.put(image);
        })
        .then(() => {
          return imageRef.getDownloadURL();
        })
        .then(url => {
          item.fileName = uploadFileName;
          item.url = url;

          return db
            .collection(collection)
            .doc(item.id)
            .update({
              fileName: uploadFileName,
              url: url
            });
        })
        .then(() => {
          commit("SHOW_SNACKBAR", {
            show: true,
            result: true,
            message: "Item created"
          });

          resolve({ message: "Item created", item: item });
        })
        .catch(err => {
          commit("SHOW_SNACKBAR", {
            show: true,
            result: false,
            message: "Error creating item"
          });

          console.log("Error: Adding Item: " + err.message);
          reject("Error adding item. " + err.message);
        });
    });
  },
  updateImageWithText({ commit, dispatch }, payload) {
    const collection = payload.collection;
    const lines = payload.lines;
    const formImageChanged = payload.formImageChanged;

    const item = {
      id: payload.item.id,
      line1: payload.item.line1,
      fileName: payload.item.fileName,
      url: payload.item.url,
      order: payload.item.order
    };

    if (payload.item.text) {
      item.text = payload.item.text
    }
    else {
      item.text = ''
    }

    if (payload.item.underlay) {
      item.underlay = payload.item.underlay
    }
    else {
      item.underlay = ''
    }

    if (lines >= 1) {
      item.line1 = payload.item.line1;
    }

    if (lines >= 2) {
      item.line2 = payload.item.line2;
    }

    if (lines >= 3) {
      item.line3 = payload.item.line3;
    }

    const image = payload.item.image;

    let imageRef;

    return new Promise((resolve, reject) => {
      dispatch("updateImageDocWithText", {
        collection: collection,
        lines: lines,
        item: item
      })
        .then(() => {
          if (!formImageChanged) {
            return;
          } else {
            return storage
              .ref()
              .child(item.fileName)
              .delete()
              .then(() => {
                imageRef = storage.ref().child(item.fileName);
                return imageRef;
              })
              .then(imageRef => {
                return imageRef.put(image);
              })
              .then(() => {
                return imageRef.getDownloadURL();
              })
              .then(url => {
                item.url = url;

                return db
                  .collection(collection)
                  .doc(item.id)
                  .update({
                    url: url
                  });
              });
          }
        })
        .then(() => {
          commit("SHOW_SNACKBAR", {
            show: true,
            result: true,
            message: "Item updated"
          });

          resolve({ message: "Item updated", item: item });
        })
        .catch(err => {
          commit("SHOW_SNACKBAR", {
            show: true,
            result: false,
            message: "Error updating item"
          });

          console.log("Error: Updating Item: " + err.message);
          reject("Error updating item. " + err.message);
        });
    });
  },
  // eslint-disable-next-line no-unused-vars
  updateImageDocWithText({ commit }, payload) {
    const collection = payload.collection;
    const lines = payload.lines;
    const item = payload.item;

    if (lines == 0) {
      return db
        .collection(collection)
        .doc(item.id)
        .update({
          fileName: item.fileName,
          url: item.url,
          text: item.text,
          underlay: item.underlay,
          order: item.order
        });
    } else if (lines == 1) {
      return db
        .collection(collection)
        .doc(item.id)
        .update({
          line1: item.line1,
          fileName: item.fileName,
          url: item.url,
          text: item.text,
          underlay: item.underlay,
          order: item.order
        });
    } else if (lines == 2) {
      return db
        .collection(collection)
        .doc(item.id)
        .update({
          line1: item.line1,
          line2: item.line2,
          fileName: item.fileName,
          url: item.url,
          text: item.text,
          underlay: item.underlay,
          order: item.order
        });
    } else {
      return db
        .collection(collection)
        .doc(item.id)
        .update({
          line1: item.line1,
          line2: item.line2,
          line3: item.line3,
          fileName: item.fileName,
          url: item.url,
          text: item.text,
          underlay: item.underlay,
          order: item.order
        });
    }
  },
  createImageWithWidth({ commit }, payload) {
    const collection = payload.collection;
    const lines = payload.lines;

    const item = {
      fileName: payload.item.fileName,
      url: payload.item.url,
      order: payload.item.order
    };

    if (payload.item.width) {
      item.width = payload.item.width
    }
    else {
      item.width = 0
    }

    if (lines >= 1) {
      item.line1 = payload.item.line1;
    }

    if (lines >= 2) {
      item.line2 = payload.item.line2;
    }

    if (lines >= 3) {
      item.line3 = payload.item.line3;
    }

    const image = payload.item.image;

    let uploadFileName;
    let imageRef;

    return new Promise((resolve, reject) => {
      db.collection(collection)
        .add(item)
        .then(doc => {
          item.id = doc.id;
          return item.id;
        })
        .then(id => {
          const fileName = image.name;
          const ext = fileName.slice(fileName.lastIndexOf("."));
          uploadFileName = collection + "/" + id + ext;

          imageRef = storage.ref().child(uploadFileName);
          return imageRef;
        })
        .then(imageRef => {
          return imageRef.put(image);
        })
        .then(() => {
          return imageRef.getDownloadURL();
        })
        .then(url => {
          item.fileName = uploadFileName;
          item.url = url;

          return db
            .collection(collection)
            .doc(item.id)
            .update({
              fileName: uploadFileName,
              url: url
            });
        })
        .then(() => {
          commit("SHOW_SNACKBAR", {
            show: true,
            result: true,
            message: "Item created"
          });

          resolve({ message: "Item created", item: item });
        })
        .catch(err => {
          commit("SHOW_SNACKBAR", {
            show: true,
            result: false,
            message: "Error creating item"
          });

          console.log("Error: Adding Item: " + err.message);
          reject("Error adding item. " + err.message);
        });
    });
  },
  updateImageWithWidth({ commit, dispatch }, payload) {
    const collection = payload.collection;
    const lines = payload.lines;
    const formImageChanged = payload.formImageChanged;

    const item = {
      id: payload.item.id,
      line1: payload.item.line1,
      fileName: payload.item.fileName,
      url: payload.item.url,
      order: payload.item.order
    };

    if (payload.item.width) {
      item.width = payload.item.width
    }
    else {
      item.width = 0
    }

    if (lines >= 1) {
      item.line1 = payload.item.line1;
    }

    if (lines >= 2) {
      item.line2 = payload.item.line2;
    }

    if (lines >= 3) {
      item.line3 = payload.item.line3;
    }

    const image = payload.item.image;

    let imageRef;

    return new Promise((resolve, reject) => {
      dispatch("updateImageDocWithWidth", {
        collection: collection,
        lines: lines,
        item: item
      })
        .then(() => {
          if (!formImageChanged) {
            return;
          } else {
            return storage
              .ref()
              .child(item.fileName)
              .delete()
              .then(() => {
                imageRef = storage.ref().child(item.fileName);
                return imageRef;
              })
              .then(imageRef => {
                return imageRef.put(image);
              })
              .then(() => {
                return imageRef.getDownloadURL();
              })
              .then(url => {
                item.url = url;

                return db
                  .collection(collection)
                  .doc(item.id)
                  .update({
                    url: url
                  });
              });
          }
        })
        .then(() => {
          commit("SHOW_SNACKBAR", {
            show: true,
            result: true,
            message: "Item updated"
          });

          resolve({ message: "Item updated", item: item });
        })
        .catch(err => {
          commit("SHOW_SNACKBAR", {
            show: true,
            result: false,
            message: "Error updating item"
          });

          console.log("Error: Updating Item: " + err.message);
          reject("Error updating item. " + err.message);
        });
    });
  },
  // eslint-disable-next-line no-unused-vars
  updateImageDocWithWidth({ commit }, payload) {
    const collection = payload.collection;
    const lines = payload.lines;
    const item = payload.item;

    if (lines == 0) {
      return db
        .collection(collection)
        .doc(item.id)
        .update({
          fileName: item.fileName,
          url: item.url,
          width: item.width,
          order: item.order
        });
    } else if (lines == 1) {
      return db
        .collection(collection)
        .doc(item.id)
        .update({
          line1: item.line1,
          fileName: item.fileName,
          url: item.url,
          width: item.width,
          order: item.order
        });
    } else if (lines == 2) {
      return db
        .collection(collection)
        .doc(item.id)
        .update({
          line1: item.line1,
          line2: item.line2,
          fileName: item.fileName,
          url: item.url,
          width: item.width,
          order: item.order
        });
    } else {
      return db
        .collection(collection)
        .doc(item.id)
        .update({
          line1: item.line1,
          line2: item.line2,
          line3: item.line3,
          fileName: item.fileName,
          url: item.url,
          width: item.width,
          order: item.order
        });
    }
  },
  createTVImage({ commit }, payload) {
    const collection = payload.collection;
    const lines = payload.lines;

    const item = {
      fileName: payload.item.fileName,
      url: payload.item.url,
      order: payload.item.order
    };

    if (lines >= 1) {
      item.line1 = payload.item.line1;
    }

    if (lines >= 2) {
      item.line2 = payload.item.line2;
    }

    if (lines >= 3) {
      item.line3 = payload.item.line3;
    }

    const image = payload.item.image;

    let uploadFileName;
    let imageRef;

    return new Promise((resolve, reject) => {
      db.collection(collection)
        .add(item)
        .then(doc => {
          item.id = doc.id;
          return item.id;
        })
        .then(id => {
          const fileName = image.name;
          const ext = fileName.slice(fileName.lastIndexOf("."));
          uploadFileName = collection + "/" + id + ext;

          imageRef = storage.ref().child(uploadFileName);
          return imageRef;
        })
        .then(imageRef => {
          return imageRef.put(image);
        })
        .then(() => {
          return imageRef.getDownloadURL();
        })
        .then(url => {
          item.fileName = uploadFileName;
          item.url = url;

          return db
            .collection(collection)
            .doc(item.id)
            .update({
              fileName: uploadFileName,
              url: url
            });
        })
        .then(() => {
          commit("SHOW_SNACKBAR", {
            show: true,
            result: true,
            message: "Item created"
          });

          resolve({ message: "Item created", item: item });
        })
        .catch(err => {
          commit("SHOW_SNACKBAR", {
            show: true,
            result: false,
            message: "Error creating item"
          });

          console.log("Error: Adding Item: " + err.message);
          reject("Error adding item. " + err.message);
        });
    });
  },
  updateTVImage({ commit, dispatch }, payload) {
    const collection = payload.collection;
    const lines = payload.lines;
    const formImageChanged = payload.formImageChanged;

    const item = {
      id: payload.item.id,
      line1: payload.item.line1,
      fileName: payload.item.fileName,
      url: payload.item.url,
      order: payload.item.order
    };

    if (lines >= 1) {
      item.line1 = payload.item.line1;
    }

    if (lines >= 2) {
      item.line2 = payload.item.line2;
    }

    if (lines >= 3) {
      item.line3 = payload.item.line3;
    }

    const image = payload.item.image;

    let imageRef;

    return new Promise((resolve, reject) => {
      dispatch("updateTVImageDoc", {
        collection: collection,
        lines: lines,
        item: item
      })
        .then(() => {
          if (!formImageChanged) {
            return;
          } else {
            return storage
              .ref()
              .child(item.fileName)
              .delete()
              .then(() => {
                imageRef = storage.ref().child(item.fileName);
                return imageRef;
              })
              .then(imageRef => {
                return imageRef.put(image);
              })
              .then(() => {
                return imageRef.getDownloadURL();
              })
              .then(url => {
                item.url = url;

                return db
                  .collection(collection)
                  .doc(item.id)
                  .update({
                    url: url
                  });
              });
          }
        })
        .then(() => {
          commit("SHOW_SNACKBAR", {
            show: true,
            result: true,
            message: "Item updated"
          });

          resolve({ message: "Item updated", item: item });
        })
        .catch(err => {
          commit("SHOW_SNACKBAR", {
            show: true,
            result: false,
            message: "Error updating item"
          });

          console.log("Error: Updating Item: " + err.message);
          reject("Error updating item. " + err.message);
        });
    });
  },
  // eslint-disable-next-line no-unused-vars
  updateTVImageDoc({ commit }, payload) {
    const collection = payload.collection;
    const lines = payload.lines;
    const item = payload.item;

    if (lines == 0) {
      return db
        .collection(collection)
        .doc(item.id)
        .update({
          fileName: item.fileName,
          url: item.url,
          order: item.order
        });
    } else if (lines == 1) {
      return db
        .collection(collection)
        .doc(item.id)
        .update({
          line1: item.line1,
          fileName: item.fileName,
          url: item.url,
          order: item.order
        });
    } else if (lines == 2) {
      return db
        .collection(collection)
        .doc(item.id)
        .update({
          line1: item.line1,
          line2: item.line2,
          fileName: item.fileName,
          url: item.url,
          order: item.order
        });
    } else {
      return db
        .collection(collection)
        .doc(item.id)
        .update({
          line1: item.line1,
          line2: item.line2,
          line3: item.line3,
          fileName: item.fileName,
          url: item.url,
          order: item.order
        });
    }
  },
  deleteImage({ commit }, payload) {
    const collection = payload.collection;
    const item = payload.item;

    return new Promise((resolve, reject) => {
      storage
        .ref()
        .child(item.fileName)
        .delete()
        .then(() => {
          return db
            .collection(collection)
            .doc(item.id)
            .delete();
        })
        .then(() => {
          commit("SHOW_SNACKBAR", {
            show: true,
            result: true,
            message: "Item deleted"
          });

          resolve("Item successfully deleted");
        })
        .catch(err => {
          commit("SHOW_SNACKBAR", {
            show: true,
            result: false,
            message: "Error deleting item"
          });

          console.log("Error: Deleting Item: " + err.message);
          reject("Error deleting item. " + err.message);
        });
    });
  },
  createList({ commit }, payload) {
    const collection = payload.collection;

    let item = {
      header: payload.header,
      title: payload.item.title,
      order: payload.item.order
    };

    return new Promise((resolve, reject) => {
      db.collection(collection)
        .add(item)
        .then(doc => {
          item.id = doc.id;
          return item.id;
        })
        .then(() => {
          commit("SHOW_SNACKBAR", {
            show: true,
            result: true,
            message: "Item created"
          });

          resolve({ message: "Item created", item: item });
        })
        .catch(err => {
          commit("SHOW_SNACKBAR", {
            show: true,
            result: false,
            message: "Error creating item"
          });

          console.log("Error: Adding Item: " + err.message);
          reject("Error adding item. " + err.message);
        });
    });
  },
  updateList({ commit }, payload) {
    const collection = payload.collection;

    let item = {
      id: payload.item.id,
      header: payload.header,
      title: payload.item.title,
      order: payload.item.order
    };

    return new Promise((resolve, reject) => {
      return db
        .collection(collection)
        .doc(item.id)
        .update({
          title: item.title,
          order: item.order
        })
        .then(() => {
          commit("SHOW_SNACKBAR", {
            show: true,
            result: true,
            message: "Item updated"
          });

          resolve({ message: "Item updated", item: item });
        })
        .catch(err => {
          commit("SHOW_SNACKBAR", {
            show: true,
            result: false,
            message: "Error updating item"
          });

          console.log("Error: Updating Item: " + err.message);
          reject("Error updating item. " + err.message);
        });
    });
  },
  deleteList({ commit }, payload) {
    const collection = payload.collection;

    let item = {
      id: payload.item.id,
      header: payload.header,
      title: payload.item.title,
      order: payload.item.order
    };

    return new Promise((resolve, reject) => {
      return db
        .collection(collection)
        .doc(item.id)
        .delete()
        .then(() => {
          commit("SHOW_SNACKBAR", {
            show: true,
            result: true,
            message: "Item deleted"
          });

          resolve({ message: "Item deleted", item: item });
        })
        .catch(err => {
          commit("SHOW_SNACKBAR", {
            show: true,
            result: false,
            message: "Error deleting item"
          });

          console.log("Error: Deleting Item: " + err.message);
          reject("Error deleting item. " + err.message);
        });
    });
  },
  createNeighborhoodList({ commit }, payload) {
    const collection = payload.collection;

    let item = {
      header: payload.header,
      title: payload.item.title,
      address: {
        street: payload.item.address.street,
        city: payload.item.address.city,
        state: payload.item.address.state,
        postalCode: payload.item.address.postalCode,
        lat: payload.item.address.lat,
        lng: payload.item.address.lng
      },
      phone: payload.item.phone,
      webSite: payload.item.webSite,
      order: payload.item.order
    };

    return new Promise((resolve, reject) => {
      db.collection(collection)
        .add(item)
        .then(doc => {
          item.id = doc.id;
          return item.id;
        })
        .then(() => {
          commit("SHOW_SNACKBAR", {
            show: true,
            result: true,
            message: "Item created"
          });

          const address =
            item.address.street +
            ", " +
            item.address.city +
            ", " +
            item.address.state +
            " " +
            item.address.postalCode;
          item.addressFull = address;

          const geoPoint = item.address.lat + ", " + item.address.lng;
          item.geoPoint = geoPoint;

          resolve({ message: "Item created", item: item });
        })
        .catch(err => {
          commit("SHOW_SNACKBAR", {
            show: true,
            result: false,
            message: "Error creating item"
          });

          console.log("Error: Adding Item: " + err.message);
          reject("Error adding item. " + err.message);
        });
    });
  },
  updateNeighborhoodList({ commit }, payload) {
    const collection = payload.collection;

    let item = {
      id: payload.item.id,
      header: payload.header,
      title: payload.item.title,
      address: {
        street: payload.item.address.street,
        city: payload.item.address.city,
        state: payload.item.address.state,
        postalCode: payload.item.address.postalCode,
        lat: payload.item.address.lat,
        lng: payload.item.address.lng
      },
      phone: payload.item.phone,
      webSite: payload.item.webSite,
      order: payload.item.order
    };

    return new Promise((resolve, reject) => {
      return db
        .collection(collection)
        .doc(item.id)
        .update({
          title: item.title,
          address: {
            street: item.address.street,
            city: item.address.city,
            state: item.address.state,
            postalCode: item.address.postalCode,
            lat: item.address.lat,
            lng: item.address.lng
          },
          phone: item.phone,
          webSite: item.webSite,
          order: item.order
        })
        .then(() => {
          commit("SHOW_SNACKBAR", {
            show: true,
            result: true,
            message: "Item updated"
          });

          const address =
            item.address.street +
            ", " +
            item.address.city +
            ", " +
            item.address.state +
            " " +
            item.address.postalCode;
          item.addressFull = address;

          const geoPoint = item.address.lat + ", " + item.address.lng;
          item.geoPoint = geoPoint;

          resolve({ message: "Item updated", item: item });
        })
        .catch(err => {
          commit("SHOW_SNACKBAR", {
            show: true,
            result: false,
            message: "Error updating item"
          });

          console.log("Error: Updating Item: " + err.message);
          reject("Error updating item. " + err.message);
        });
    });
  },
  deleteNeighborhoodList({ commit }, payload) {
    const collection = payload.collection;

    let item = {
      id: payload.item.id,
      header: payload.header,
      title: payload.item.title,
      address: {
        street: payload.item.address.street,
        city: payload.item.address.city,
        state: payload.item.address.state,
        postalCode: payload.item.address.postalCode,
        lat: payload.item.address.lat,
        lng: payload.item.address.lng
      },
      phone: payload.item.phone,
      webSite: payload.item.webSite,
      order: payload.item.order
    };

    return new Promise((resolve, reject) => {
      return db
        .collection(collection)
        .doc(item.id)
        .delete()
        .then(() => {
          commit("SHOW_SNACKBAR", {
            show: true,
            result: true,
            message: "Item deleted"
          });

          const address =
            item.address.street +
            ", " +
            item.address.city +
            ", " +
            item.address.state +
            " " +
            item.address.postalCode;
          item.addressFull = address;

          const geoPoint = item.address.lat + ", " + item.address.lng;
          item.geoPoint = geoPoint;

          resolve({ message: "Item deleted", item: item });
        })
        .catch(err => {
          commit("SHOW_SNACKBAR", {
            show: true,
            result: false,
            message: "Error deleting item"
          });

          console.log("Error: Deleting Item: " + err.message);
          reject("Error deleting item. " + err.message);
        });
    });
  },
  createVirtualTourList({ commit }, payload) {
    const collection = payload.collection;

    let item = {
      virtualTour: {
        title: payload.item.virtualTour.title,
        url: payload.item.virtualTour.url
      },
      walkthroughVideo: {
        title: payload.item.walkthroughVideo.title,
        url: payload.item.walkthroughVideo.url
      },
      order: payload.item.order
    };

    return new Promise((resolve, reject) => {
      db.collection(collection)
        .add(item)
        .then(doc => {
          item.id = doc.id;
          return item.id;
        })
        .then(() => {
          commit("SHOW_SNACKBAR", {
            show: true,
            result: true,
            message: "Item created"
          });

          resolve({ message: "Item created", item: item });
        })
        .catch(err => {
          commit("SHOW_SNACKBAR", {
            show: true,
            result: false,
            message: "Error creating item"
          });

          console.log("Error: Adding Item: " + err.message);
          reject("Error adding item. " + err.message);
        });
    });
  },
  updateVirtualTourList({ commit }, payload) {
    const collection = payload.collection;

    let item = {
      id: payload.item.id,
      virtualTour: {
        title: payload.item.virtualTour.title,
        url: payload.item.virtualTour.url
      },
      walkthroughVideo: {
        title: payload.item.walkthroughVideo.title,
        url: payload.item.walkthroughVideo.url
      },
      order: payload.item.order
    };

    return new Promise((resolve, reject) => {
      return db
        .collection(collection)
        .doc(item.id)
        .update({
          virtualTour: {
            title: item.virtualTour.title,
            url: item.virtualTour.url
          },
          walkthroughVideo: {
            title: item.walkthroughVideo.title,
            url: item.walkthroughVideo.url
          },
          order: item.order
        })
        .then(() => {
          commit("SHOW_SNACKBAR", {
            show: true,
            result: true,
            message: "Item updated"
          });

          resolve({ message: "Item updated", item: item });
        })
        .catch(err => {
          commit("SHOW_SNACKBAR", {
            show: true,
            result: false,
            message: "Error updating item"
          });

          console.log("Error: Updating Item: " + err.message);
          reject("Error updating item. " + err.message);
        });
    });
  },
  deleteVirtualTourList({ commit }, payload) {
    const collection = payload.collection;

    let item = {
      id: payload.item.id,
      virtualTour: {
        title: payload.item.virtualTour.title,
        url: payload.item.virtualTour.url
      },
      walkthroughVideo: {
        title: payload.item.walkthroughVideo.title,
        url: payload.item.walkthroughVideo.url
      },
      order: payload.item.order
    };

    return new Promise((resolve, reject) => {
      return db
        .collection(collection)
        .doc(item.id)
        .delete()
        .then(() => {
          commit("SHOW_SNACKBAR", {
            show: true,
            result: true,
            message: "Item deleted"
          });

          resolve({ message: "Item deleted", item: item });
        })
        .catch(err => {
          commit("SHOW_SNACKBAR", {
            show: true,
            result: false,
            message: "Error deleting item"
          });

          console.log("Error: Deleting Item: " + err.message);
          reject("Error deleting item. " + err.message);
        });
    });
  },
  getApplyHereList({ commit }) {
    db.collection("apply-here")
      .orderBy("createdDate")
      .get()
      .then(snapshot => {
        if (snapshot && snapshot.docs) {
          commit("GET_APPLY_HERE_LIST", snapshot.docs);
        } else {
          console.log("No apply-here found");
        }
      })
      .catch(err => {
        console.log("Error: Getting apply-here: " + err.message);
      });
  },
  getContactUsList({ commit }) {
    db.collection("contact-us")
      .orderBy("createdDate")
      .get()
      .then(snapshot => {
        if (snapshot && snapshot.docs) {
          commit("GET_CONTACT_US_LIST", snapshot.docs);
        } else {
          console.log("No contact-us found");
        }
      })
      .catch(err => {
        console.log("Error: Getting contact-us: " + err.message);
      });
  }
};
