import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/functions";
import "firebase/storage";
import store from "@/store";

const firebaseConfig = {
  apiKey: "AIzaSyAR-P9hSllMRYCPZxXf0L8DrrFfTEEfJ3M",
  authDomain: "timothy-court-apartments.firebaseapp.com",
  projectId: "timothy-court-apartments",
  storageBucket: "timothy-court-apartments.appspot.com",
  messagingSenderId: "506502107757",
  appId: "1:506502107757:web:200cccc0b1637d334aa564"
};

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const auth = firebase.auth();
const functions = firebase.functions();
var storage = firebase.storage();

auth.onAuthStateChanged(user => {
  if (user) {
    user.getIdTokenResult().then(idTokenResult => {
      user.admin = idTokenResult.claims.admin;
    });

    store.dispatch("updateUser", user);
  } else {
    store.dispatch("updateUser", null);
  }

  store.dispatch("changeAuthReady");
});

export { db, auth, functions, storage };
