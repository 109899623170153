import store from ".";

export default {
  UPDATE_TOOLBAR_NAVIGATION(state) {
    state.toolbarNavigation[0].title = state.property.name;

    state.toolbarNavigation[2].title = state.property.phone;
    state.toolbarNavigation[2].path = state.property.phone.replace(
      /[^0-9]/g,
      ""
    );

    state.toolbarNavigation[3].path = state.property.email;

    state.toolbarNavigation[5].path = state.property.tenantsLoginUrl;
  },
  UPDATE_SIDE_NAVIGATION(state) {
    state.sideNavigation[0].title = state.property.name;

    state.sideNavigation[1].title = state.property.phone;
    state.sideNavigation[1].path = state.property.phone.replace(/[^0-9]/g, "");

    state.sideNavigation[9].title = state.property.email;
    state.sideNavigation[9].path = state.property.email;

    state.sideNavigation[10].title = store.getters.propertyAddress.line1;
    state.sideNavigation[10].subTitle = store.getters.propertyAddress.line2;
    state.sideNavigation[10].path =
      state.property.address.lat + "," + state.property.address.lng;
    state.sideNavigation[10].options = state.property.address.googlePlaceID;

    state.sideNavigation[12].path = state.property.tenantsLoginUrl;
  },
  UPDATE_WINDOW() {
    document.title =
      store.getters.property.titleKeywords +
      " | " +
      store.getters.property.name;
  },
  GET_PROPERTY_DETAILS_CONTENT(state, docs) {
    docs.forEach(doc => {
      let item = doc.data();
      state.propertyDetails = item.text;

      return;
    });
  },
  GET_SIDE_NAVIGATION_IMAGES(state, docs) {
    state.sideNavigationImages = [];

    docs.forEach(doc => {
      state.sideNavigationImages.push({
        ...doc.data(),
        id: doc.id
      });
    });

    if (state.sideNavigationImages && state.sideNavigationImages.length > 0) {
      state.sideNavigation[0].image = state.sideNavigationImages[0].url;
    }
  },
  GET_CAROUSEL_IMAGES(state, docs) {
    state.carouselImages = [];

    docs.forEach(doc => {
      state.carouselImages.push({
        ...doc.data(),
        id: doc.id
      });
    });

    state.carouselImages.forEach(item => {
      let textAssigned = false

      item.textLeftDefault = 0.05
      item.textTopDefault = 0.65
      item.textColor = 'white'
      item.textShadow = '0, 0, 0, 0.7'

      if (item.text) {
        if (item.text.includes(";")) {
          const text = item.text.split(";")

          if (text.length == 7) {
            textAssigned = true

            item.textLeft = parseFloat(text[0])
            item.textTop = parseFloat(text[1])
            item.textColor = text[2]
            item.textShadow = text[3] + ', ' + text[4] + ', ' + text[5] + ', ' + text[6]
          }
        }
      }

      if (!textAssigned) {
        item.textLeft = item.textLeftDefault
        item.textTop = item.textTopDefault
      }

      item.underlayMargin = 5
      item.underlayHeight = 100
      item.underlayWidth = 250
      item.underlayColor = 'black'
      item.underlayOpacity = 0.0

      if (item.underlay) {
        if (item.underlay.includes(";")) {
          const underlay = item.underlay.split(";")

          if (underlay.length == 5) {
            item.underlayMargin = parseFloat(underlay[0])
            item.underlayHeight = parseFloat(underlay[1])
            item.underlayWidth = parseFloat(underlay[2])
            item.underlayColor = underlay[3]
            item.underlayOpacity = parseFloat(underlay[4])
          }
        }
      }
    });
  },
  GET_LIGHTBOX_IMAGES(state, docs) {
    state.lightboxImages = [];

    docs.forEach(doc => {
      state.lightboxImages.push({
        ...doc.data(),
        id: doc.id
      });
    });

    state.lightboxImages.forEach(item => {
      let textAssigned = false

      item.textLeftDefault = 0.05
      item.textTopDefault = 0.70
      item.textColor = 'white'
      item.textShadow = '0, 0, 0, 0.7'

      if (item.text) {
        if (item.text.includes(";")) {
          const text = item.text.split(";")

          if (text.length == 7) {
            textAssigned = true

            item.textLeft = parseFloat(text[0])
            item.textTop = parseFloat(text[1])
            item.textColor = text[2]
            item.textShadow = text[3] + ', ' + text[4] + ', ' + text[5] + ', ' + text[6]
          }
        }
      }

      if (!textAssigned) {
        item.textLeft = item.textLeftDefault
        item.textTop = item.textTopDefault
      }

      item.underlayMargin = 5
      item.underlayHeight = 100
      item.underlayWidth = 250
      item.underlayColor = 'black'
      item.underlayOpacity = 0.0

      if (item.underlay) {
        if (item.underlay.includes(";")) {
          const underlay = item.underlay.split(";")

          if (underlay.length == 5) {
            item.underlayMargin = parseFloat(underlay[0])
            item.underlayHeight = parseFloat(underlay[1])
            item.underlayWidth = parseFloat(underlay[2])
            item.underlayColor = underlay[3]
            item.underlayOpacity = parseFloat(underlay[4])
          }
        }
      }
    });
  },
  GET_FLOOR_PLANS_IMAGES(state, docs) {
    state.floorPlanImages = [];

    docs.forEach(doc => {
      state.floorPlanImages.push({
        ...doc.data(),
        id: doc.id
      });
    });
  },
  GET_AMENITIES_IMAGES(state, docs) {
    state.amenitiesImages = [];

    docs.forEach(doc => {
      state.amenitiesImages.push({
        ...doc.data(),
        id: doc.id
      });
    });
  },
  GET_AMENITIES_LIST(state, docs) {
    state.amenitiesList = [];

    const propertyList = [];
    const unitList = [];

    docs.forEach(doc => {
      let item = doc.data();
      item.id = doc.id;

      if (item.header == "Property") {
        propertyList.push({
          id: item.id,
          title: item.title,
          order: item.order
        });
      } else if (item.header == "Unit") {
        unitList.push({
          id: item.id,
          title: item.title,
          order: item.order
        });
      }
    });

    state.amenitiesList.push({
      header: "Property",
      list: propertyList
    });

    state.amenitiesList.push({
      header: "Unit",
      list: unitList
    });
  },
  GET_UTILITIES_IMAGES(state, docs) {
    state.utilitiesImages = [];

    docs.forEach(doc => {
      state.utilitiesImages.push({
        ...doc.data(),
        id: doc.id
      });
    });
  },
  GET_UTILITIES_LIST(state, docs) {
    state.utilitiesList = [];

    const paidByLandlordList = [];
    const paidByTenantList = [];

    docs.forEach(doc => {
      let item = doc.data();
      item.id = doc.id;

      if (item.header == "Paid By Landlord") {
        paidByLandlordList.push({
          id: item.id,
          title: item.title,
          order: item.order
        });
      } else if (item.header == "Paid By Tenant") {
        paidByTenantList.push({
          id: item.id,
          title: item.title,
          order: item.order
        });
      }
    });

    state.utilitiesList.push({
      header: "Paid By Landlord",
      list: paidByLandlordList
    });

    state.utilitiesList.push({
      header: "Paid By Tenant",
      list: paidByTenantList
    });
  },
  GET_VIRTUAL_TOUR(state, docs) {
    state.virtualTour = [];

    docs.forEach(doc => {
      let item = doc.data();

      let walkthroughVideoTitle = "";
      if (item.walkthroughVideo.title)
        walkthroughVideoTitle = item.walkthroughVideo.title;

      let youTubeUrl = "";
      if (item.walkthroughVideo.youTubeId)
        youTubeUrl =
          "https://www.youtube.com/embed/" + item.walkthroughVideo.youTubeId;

      let virtualTourTitle = "";
      if (item.walkthroughVideo.title)
        virtualTourTitle = item.virtualTour.title;

      let matterportUrl = "";
      if (item.virtualTour.matterportId)
        matterportUrl =
          "https://my.matterport.com/show/?m=" +
          item.virtualTour.matterportId +
          "&nt=1";

      state.virtualTour.push({
        walkthroughVideo: {
          title: walkthroughVideoTitle,
          url: youTubeUrl
        },
        virtualTour: {
          title: virtualTourTitle,
          url: matterportUrl
        }
      });
    });
  },
  GET_NEIGHBORHOOD_LIST(state, docs) {
    state.neighborhoodList = [];

    const entertainmentList = [];
    const shoppingList = [];
    const diningList = [];
    const groceriesList = [];
    const banksList = [];
    const fitnessList = [];

    docs.forEach(doc => {
      let item = doc.data();
      item.id = doc.id;

      const address =
        item.address.street +
        ", " +
        item.address.city +
        ", " +
        item.address.state +
        " " +
        item.address.postalCode;

      const geoPoint = item.address.lat + ", " + item.address.lng;

      if (item.header == "Entertainment") {
        entertainmentList.push({
          id: item.id,
          title: item.title,
          phone: item.phone,
          address: {
            street: item.address.street,
            city: item.address.city,
            state: item.address.state,
            postalCode: item.address.postalCode,
            geoPoint: geoPoint,
            lat: item.address.lat,
            lng: item.address.lng
          },
          addressFull: address,
          geoPoint: geoPoint,
          webSite: item.webSite,
          order: item.order
        });
      } else if (item.header == "Shopping") {
        shoppingList.push({
          id: item.id,
          title: item.title,
          phone: item.phone,
          address: {
            street: item.address.street,
            city: item.address.city,
            state: item.address.state,
            postalCode: item.address.postalCode,
            geoPoint: geoPoint,
            lat: item.address.lat,
            lng: item.address.lng
          },
          addressFull: address,
          geoPoint: geoPoint,
          webSite: item.webSite,
          order: item.order
        });
      } else if (item.header == "Dining") {
        diningList.push({
          id: item.id,
          title: item.title,
          phone: item.phone,
          address: {
            street: item.address.street,
            city: item.address.city,
            state: item.address.state,
            postalCode: item.address.postalCode,
            geoPoint: geoPoint,
            lat: item.address.lat,
            lng: item.address.lng
          },
          addressFull: address,
          geoPoint: geoPoint,
          webSite: item.webSite,
          order: item.order
        });
      } else if (item.header == "Groceries") {
        groceriesList.push({
          id: item.id,
          title: item.title,
          phone: item.phone,
          address: {
            street: item.address.street,
            city: item.address.city,
            state: item.address.state,
            postalCode: item.address.postalCode,
            geoPoint: geoPoint,
            lat: item.address.lat,
            lng: item.address.lng
          },
          addressFull: address,
          geoPoint: geoPoint,
          webSite: item.webSite,
          order: item.order
        });
      } else if (item.header == "Banks") {
        banksList.push({
          id: item.id,
          title: item.title,
          phone: item.phone,
          address: {
            street: item.address.street,
            city: item.address.city,
            state: item.address.state,
            postalCode: item.address.postalCode,
            geoPoint: geoPoint,
            lat: item.address.lat,
            lng: item.address.lng
          },
          addressFull: address,
          geoPoint: geoPoint,
          webSite: item.webSite,
          order: item.order
        });
      } else if (item.header == "Fitness") {
        fitnessList.push({
          id: item.id,
          title: item.title,
          phone: item.phone,
          address: {
            street: item.address.street,
            city: item.address.city,
            state: item.address.state,
            postalCode: item.address.postalCode,
            geoPoint: geoPoint,
            lat: item.address.lat,
            lng: item.address.lng
          },
          addressFull: address,
          geoPoint: geoPoint,
          webSite: item.webSite,
          order: item.order
        });
      }
    });

    state.neighborhoodList.push({
      header: "Entertainment",
      list: entertainmentList
    });

    state.neighborhoodList.push({
      header: "Shopping",
      list: shoppingList
    });

    state.neighborhoodList.push({
      header: "Dining",
      list: diningList
    });

    state.neighborhoodList.push({
      header: "Groceries",
      list: groceriesList
    });

    state.neighborhoodList.push({
      header: "Banks",
      list: banksList
    });

    state.neighborhoodList.push({
      header: "Fitness",
      list: fitnessList
    });
  },
  GET_VIRTUAL_TOUR_LIST(state, docs) {
    state.virtualTourList = [];

    docs.forEach(doc => {
      let item = doc.data();
      item.id = doc.id;

      state.virtualTourList.push({
        id: item.id,
        virtualTour: {
          title: item.virtualTour.title,
          url: item.virtualTour.url
        },
        walkthroughVideo: {
          title: item.walkthroughVideo.title,
          url: item.walkthroughVideo.url
        },
        order: item.order
      });
    });
  },
  OPEN_CONTACT_US_DIALOG(state, open) {
    state.contactUsDialog = open;
  },
  CONTACT_US_LOADING(state, loading) {
    state.contactUsLoading = loading;
  },
  APPLY_HERE_LOADING(state, loading) {
    state.applyHereLoading = loading;
  },
  GET_TV_IMAGES(state, docs) {
    state.tvImages = [];

    docs.forEach(doc => {
      state.tvImages.push({
        ...doc.data(),
        id: doc.id
      });
    });
  },
  GET_TV_LOG(state, docs) {
    state.tvLog = [];

    docs.forEach(doc => {
      state.tvLog.push({
        ...doc.data()
      });
    });

    let i = 0

    state.tvLog.forEach(item => {
      let date = item.logDate.toDate()
      date.setHours(date.getHours() + 5)
      item.logDate = date.toLocaleString()

      item.order = i
      i = i + 1
    });
  },
  SHOW_SNACKBAR(state, payload) {
    state.snackbar.show = payload.show;

    if (payload.result) {
      state.snackbar.color = "success";
      state.snackbar.timeout = 4000;
      state.snackbar.message = payload.message;
    } else {
      state.snackbar.color = "error";
      state.snackbar.timeout = 5000;
      state.snackbar.message = payload.message;
    }
  },
  CHANGE_AUTH_READY(state) {
    state.authReady = true;
  },
  CHANGE_LOGIN_DIALOG_TYPE(state, type) {
    state.loginDialogType = type;
  },
  OPEN_LOGIN_DIALOG(state, open) {
    state.loginDialog = open;
  },
  LOGIN_LOADING(state, loading) {
    state.loginLoading = loading;
  },
  UPDATE_USER(state, user) {
    state.user = user;
  },
  UPDATE_TOOLBAR_NAVIGATION_ADMIN(state) {
    state.toolbarNavigationAdmin[0].title = state.property.name;
  },
  UPDATE_PROPERTY_DETAILS_CONTENT(state, propertyDetails) {
    state.propertyDetails = propertyDetails;
  },
  GET_APPLY_HERE_LIST(state, docs) {
    state.applyHereList = [];

    docs.forEach(doc => {
      state.applyHereList.push({
        ...doc.data(),
        id: doc.id
      });
    });

    state.applyHereList.forEach(item => {
      item.name = item.firstName + " " + item.lastName;
      item.createdDate = item.createdDate.toDate().toLocaleString();
    });
  },
  GET_CONTACT_US_LIST(state, docs) {
    state.contactUsList = [];

    docs.forEach(doc => {
      state.contactUsList.push({
        ...doc.data(),
        id: doc.id
      });
    });

    state.contactUsList.forEach(item => {
      item.name = item.firstName + " " + item.lastName;
      item.createdDate = item.createdDate.toDate().toLocaleString();
    });
  }
};
