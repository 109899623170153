<template>
  <div>
    <HomeCarousel />
    <PropertyDetailsContent :property= property :propertyDetails= propertyDetails :items= lightboxImages @show="show" />
    <FloorPlans :items= floorPlanImages />
    <Amenities :items= amenitiesImages :propertyList= amenitiesPropertyList :unitList= amenitiesUnitList />
    <Utilities :items= utilitiesImages :paidByLandlordList= utilitiesPaidByLandlordList :paidByTenantList= utilitiesPaidByTenantList />

    <v-container fluid fill-height class="lightbox" style="max-height: 100vh;" v-if="lightbox.visible" @click.stop="hide">
      <v-layout row v-resize="onResize" style="margin-left: 0px; margin-right: 0px;">
        <v-flex xs12>
          <v-layout justify-center align-center class="lightbox-height" @click.stop="hide">
            <v-layout justify-center align-center class="lightbox-image">
              <img id="lightboxImage" :src="lightboxImages[lightbox.index].url" />
            </v-layout>
          </v-layout>
        </v-flex>
      </v-layout>

      <div class="lightbox-nav" :style="{ padding: lightbox.navHeight + 'px 0'}" @click.stop="prev" :class="{'invisible': ! hasPrev()}">
        <svg fill="#fff" height="48" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.41 16.09l-4.58-4.59 4.58-4.59L14 5.5l-6 6 6 6z"/>
            <path d="M0-.5h24v24H0z" fill="none"/>
        </svg>
      </div>

      <div class="lightbox-nav" :style="{ padding: lightbox.navHeight + 'px 0', right: '0'}" @click.stop="next" :class="{'invisible': ! hasNext()}">
        <svg fill="#fff" height="48" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z"/>
            <path d="M0-.25h24v24H0z" fill="none"/>
        </svg>
      </div>

      <div class="lightbox-page" :style="{ top: closeButtonTopPos() + 'px', left: '20px' }">
        {{ lightbox.index + 1 }} of {{ lightboxImages.length}}
      </div>

      <div id="lightboxCloseButton" class="lightbox-close-button" :style="{ top: closeButtonTopPos() + 'px' }">
        <svg height="48" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <line x1="1" y1="11" x2="11" y2="1" stroke="white" stroke-width="2"/>
          <line x1="1" y1="1" x2="11" y2="11" stroke="white" stroke-width="2"/>
        </svg>
      </div>

      <div v-if="$vuetify.breakpoint.smAndDown" class="lightbox-text" :style="{ top: textTopPos(lightboxImages[lightbox.index]) - lightboxImages[lightbox.index].underlayMargin / 2 + 'px', 
        left: textLeftPos(lightboxImages[lightbox.index]) - lightboxImages[lightbox.index].underlayMargin + 'px', 
        height: lightboxImages[lightbox.index].underlayHeight * divScale + 'px',
        width: lightboxImages[lightbox.index].underlayWidth * divScale + 'px',
        backgroundColor: lightboxImages[lightbox.index].underlayColor,
        opacity: lightboxImages[lightbox.index].underlayOpacity,
        borderRadius: lightboxImages[lightbox.index].underlayMargin * divScale / 2 + 'px' }"></div>
      <div v-if="$vuetify.breakpoint.mdAndUp" class="lightbox-text" :style="{ top: textTopPos(lightboxImages[lightbox.index]) - lightboxImages[lightbox.index].underlayMargin / 2 + 'px', 
        left: textLeftPos(lightboxImages[lightbox.index]) - lightboxImages[lightbox.index].underlayMargin + 'px', 
        height: lightboxImages[lightbox.index].underlayHeight + 'px',
        width: lightboxImages[lightbox.index].underlayWidth + 'px',
        backgroundColor: lightboxImages[lightbox.index].underlayColor,
        opacity: lightboxImages[lightbox.index].underlayOpacity,
        borderRadius: lightboxImages[lightbox.index].underlayMargin / 2 + 'px' }"></div>
        
      <div class="lightbox-text" :style="{ top: textTopPos(lightboxImages[lightbox.index]) + 'px', 
        left: textLeftPos(lightboxImages[lightbox.index]) + 'px', 
        color: lightboxImages[lightbox.index].textColor, 
        textShadow: '1px 1px 5px rgba(' + lightboxImages[lightbox.index].textShadow + ')'}">
        <div :class="{'lightbox-title-sm': $vuetify.breakpoint.smAndDown, 'lightbox-title': $vuetify.breakpoint.mdAndUp}">{{ lightboxImages[lightbox.index].line1 }}</div>
        <div :class="{'lightbox-sub-title-sm': $vuetify.breakpoint.smAndDown, 'lightbox-sub-title': $vuetify.breakpoint.mdAndUp}">{{ lightboxImages[lightbox.index].line2 }}</div>
        <div :class="{'lightbox-sub-title-sm': $vuetify.breakpoint.smAndDown, 'lightbox-sub-title': $vuetify.breakpoint.mdAndUp}">{{ lightboxImages[lightbox.index].line3 }}</div>
      </div>
    </v-container>
  </div>
</template>

<script>
import HomeCarousel from "@/components/HomeCarousel";
import PropertyDetailsContent from "@/components/PropertyDetailsContent";
import FloorPlans from "@/components/FloorPlans";
import Amenities from "@/components/Amenities";
import Utilities from "@/components/Utilities";
import { mapGetters } from "vuex";

export default {
  name: "Home",

  components: {
    HomeCarousel,
    PropertyDetailsContent,
    FloorPlans,
    Amenities,
    Utilities
  },

  data() {
    return {
      lightbox: {
        index: 0,
        visible: false,
        imageHeight: 0,
        imageWidth: 0,
        closeButtonTop: 0,
        navHeight: 0
      },
      divScale: 0.75
    };
  },

  computed: {
    ...mapGetters([
      "property",
      "lightboxImages",
      "floorPlanImages",
      "propertyDetails",
      "amenitiesImages",
      "amenitiesPropertyList",
      "amenitiesUnitList",
      "utilities",
      "utilitiesImages",
      "utilitiesPaidByLandlordList",
      "utilitiesPaidByTenantList"
    ]),
    lightboxImagesPage() {
      return this.$store.getters.lightboxImages.slice(0, 6);
    }
  },

  methods: {
    onResize() {
      var image = document.querySelector("#lightboxImage");

      if (!image) {
        return;
      }

      const lightboxImageHeight = image.offsetHeight;
      if (lightboxImageHeight == this.lightbox.imageHeight) {
        return;
      }

      this.lightbox.imageHeight = lightboxImageHeight;
      this.lightbox.navHeight = (lightboxImageHeight - 54) / 2;
    },
    textLeftPos(image) {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };

      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return this.windowSize.x * image.textLeftDefault;
      }
      return this.windowSize.x * image.textLeft;
    },
    textTopPos(image) {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };

      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return this.windowSize.y * image.textTopDefault;
      }
      return this.windowSize.y * image.textTop;
    },
    closeButtonTopPos() {
      var closeButton = document.querySelector("#lightboxCloseButton");
      var image = document.querySelector("#lightboxImage");

      if (!closeButton || !image) {
        return this.closeButtonTop;
      }

      if (image.offsetTop > closeButton.clientHeight) {
        this.closeButtonTop = image.offsetTop - closeButton.clientHeight;
      } else {
        this.closeButtonTop = 10;
      }

      return this.closeButtonTop;
    },
    show(index) {
      this.lightbox.index = parseInt(index);
      this.lightbox.visible = true;
    },
    hide() {
      this.lightbox.visible = false;
      this.lightbox.index = 0;
    },
    hasNext() {
      return this.lightbox.index + 1 < this.lightboxImages.length;
    },
    hasPrev() {
      return this.lightbox.index - 1 >= 0;
    },
    prev() {
      if (this.hasPrev()) {
        this.lightbox.index -= 1;
      }
    },
    next() {
      if (this.hasNext()) {
        this.lightbox.index += 1;
      }
    },
    imageLoaded() {
      this.onResize();
    },
    onKeydown(e) {
      if (this.lightbox.visible) {
        switch (e.key) {
          case "ArrowRight":
            this.next();
            break;
          case "ArrowLeft":
            this.prev();
            break;
          case "ArrowDown":
          case "ArrowUp":
          case " ":
            e.preventDefault();
            break;
          case "Escape":
            this.hide();
            break;
        }
      }
    }
  },

  mounted() {
    window.addEventListener("keydown", this.onKeydown);
    this.onResize();
  },

  destroyed() {
    window.removeEventListener("keydown", this.onKeydown);
  }
};
</script>

<style scoped>
.lightbox {
  position: fixed;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1000;
}

.lightbox-image img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

.lightbox-height {
  height: 100%;
  cursor: pointer;
}

.invisible {
  display: none;
}

.lightbox-nav {
  position: absolute;
  z-index: 999;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.1);
}

.lightbox-page {
  color: white;
  font-size: 1em;
  width: 100%;
  position: absolute;
  z-index: 999;
  left: 0px;
  top: 0px;
}

.lightbox-close-button {
  color: white;
  position: absolute;
  z-index: 999;
  cursor: pointer;
  right: 40px;
  top: 0px;
}

.lightbox-text {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 999;
}

.lightbox-title {
  font-size: 1.3em;
}

.lightbox-title-sm {
  font-size: 1em;
}

.lightbox-sub-title {
  font-size: 1.1em;
}

.lightbox-sub-title-sm {
  font-size: 0.8em;
}
</style>